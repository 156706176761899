@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Jomolhari&family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

html{
  scroll-behavior: smooth;
}

*::before, *::after{
  margin:0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a{
  color: inherit;
  text-decoration: none;
}

ul, ol{
  list-style-type: none;
}

body{
  font-family: "Jost", sans-serif;
  scroll-behavior: smooth;
}

.heading-1{
  font-family: "Jomolhari", serif;
}

.custom-shadow{
  -webkit-box-shadow: 0px 10px 14px 0px rgba(0,0,0,0.12);
  -moz-box-shadow: 0px 10px 14px 0px rgba(0,0,0,0.12);
  box-shadow: 0px 10px 14px 0px rgba(0,0,0,0.12);
}

.custom-transition{
  transition: 1s ease;
}

/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}


.custom-transition{
  transition: 1s ease;
}

/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
  
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
  
  .d-lg-none {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
  
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}
